import './App.css';

import { Row, Col } from 'react-bootstrap';

function App() {
  return (
    <div>
      <div className='mb-3'>
        Apps by Media Geek:
      </div>
      <div>
        Video info Checker (iOS):
        <ul>
          <li>This iOS app is to check the info of videos and images in iPhone camera roll.</li>
          <li>Supported info: bitrate, encoding, color primaries, fps, height & width, file size, audio sampling rate, etc.</li>
        </ul>
      </div>
      <div>
        Video Diff (macOS):
        <ul>
          <li>This app plays two videos side-by-side in sync, making it easy to compare videos visually.</li>
          <li>Supported keyboard shortcuts: next frame, previous frame, play/pause, etc.</li>
          <li>Use cases: compare video encodings, etc.</li>
        </ul>
      </div>
    </div>
  );
}

export default App;
